import { BaseChatInfo } from "@/includes/types/Chat/types";
import { RawTriggerData, TriggersStatistics } from "@/components/TriggerSetup/logic/types/types";

import PostApi from "piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi";

export default class TriggersApi {
  static async getChatTriggers(chat_id: BaseChatInfo['chat_id']) {
    return PostApi.sendRequest<{triggers: Array<RawTriggerData>}>("tg", 'getchattriggers', { chat_id })
  }

  static async setTrigger(trigger: RawTriggerData) {
    return PostApi.sendRequest<RawTriggerData>("tg", 'settrigger', trigger)
  }

  static async deleteTrigger(id: Required<Pick<RawTriggerData, 'id'>>['id']) {
    return PostApi.sendRequest<void>("tg", 'deletetrigger', { id })
  }

  static async getTrigger(id: Required<Pick<RawTriggerData, 'id'>>['id']) {
    return PostApi.sendRequest<{ trigger: RawTriggerData }>("tg", 'gettrigger', { id })
  }

  static async getChatTriggersStat(chat_id: BaseChatInfo['chat_id']) {
    return PostApi.sendRequest<TriggersStatistics>("tg", 'getchattriggersstat', { chat_id })
  }
}
