import VuTriggers from "../../includes/logic/VuTriggers";
import { RootState } from "@/store/state";
import { ITriggersState } from "@/store/triggers/state";

import { Commit } from "vuex";

export default {
  updateTriggers({ commit, state }: { commit: Commit, state: RootState }) {
    if (state.triggersState.loadingTriggerPromise === null) {
      const loadingTriggerPromise: ITriggersState['loadingTriggerPromise'] = new Promise<void>((resolve) => {
        VuTriggers.getTriggers()
            .then(res => {
              if (res) {
                commit("update_triggers", res);

                VuTriggers.updateFrontendTriggers();
              } else {
                commit("update_triggers", null);
              }

              resolve();
            })
            .finally(() => {
              commit('setObjectField', {
                object: state.triggersState,
                objectPath: "loadingTriggerPromise",
                value: null,
              })
            });
      });

      commit('setObjectField', {
        object: state.triggersState,
        objectPath: "loadingTriggerPromise",
        value: loadingTriggerPromise,
      })

      return loadingTriggerPromise
    }

    return state.triggersState.loadingTriggerPromise
  },
};
