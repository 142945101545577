import { RootState } from "../state";
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import { ITriggersState } from "@/store/triggers/state";

import { cloneDeep } from "lodash";

export default {
  update_triggers(state: RootState, val: ITriggersState['triggers']) {
    state.triggersState.triggers = val;
  },
  updateFrontendTriggers(state: RootState, val: ITriggersState['frontendTriggers']) {
    state.triggersState.frontendTriggers = val;
  },
  deleteFrontendTrigger(state: RootState, id: string) {
    if (state.triggersState.frontendTriggers) {
      state.triggersState.frontendTriggers = state.triggersState.frontendTriggers.filter(t => t.id !== id);
    }
  },
  setNewTrigger(state: RootState, trigger: RawTriggerData) {
    state.triggersState.newTrigger = cloneDeep(trigger);
  },
  resetNewTrigger(state: RootState) {
    state.triggersState.newTrigger = null;
  },
  resetFrontendTriggers(state: RootState) {
    state.triggersState.frontendTriggers = null
    state.triggersState.triggers = null
  },
  updatedTriggerId(state: RootState, triggerId: string) {
    state.triggersState.updatedTriggerId = triggerId;
  },
};
