import { ModuleGroupEnum, ModuleIcon } from "@/includes/logic/Modules/types/types";

import ModuleBuilder from "@/includes/logic/Modules/Builders/ModuleBuilder";
import ReportCommandHandlerModule
  from "@/includes/logic/Modules/models/modules/ReportCommandHandlerModule/ReportCommandHandlerModule";

export default class ReportCommandHandlerModuleBuilder extends ModuleBuilder {
  model!: ReportCommandHandlerModule;

  view: ModuleBuilder['view'] = () => import('../../../../../../components/Modules/modules/ReportCommandHandlerModuleView.vue')
  group: ModuleGroupEnum = ModuleGroupEnum.ModerationCommands

  icon: ModuleIcon = {
    type: "Pack",
    pack: 'material-icons',
    name: 'mark_chat_read',
  }

  aliases = [
      'жалоба',
    'report',
    'репорт',
    'admin',
  ]
}

