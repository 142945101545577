import i18n from "@/i18n/i18n";
import Store from "@/store/store";
import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import TriggersApi from "@/includes/Api/Triggers.api";
import { getTriggerTemplate } from "@/components/TriggerSetup/logic/trigger/triggerTemplates";
import { errorNotification } from "@/includes/NotificationService";

import { AxiosResponse } from "axios";
import Vue from "vue";
import { cloneDeep } from "lodash";

export default class VuTriggers {
  static getTriggers() {
    if (Store.state.chatState.chat) {
      return TriggersApi.getChatTriggers(Store.state.chatState.chat.chat_id)
          .then(res => res.data.triggers)
          .catch(error => {
            errorNotification(error)

            return [] as Array<RawTriggerData>
          })
    } else {
      return Promise.resolve(null)
    }
  }

  static setTrigger(trigger: RawTriggerData) {
    return new Promise<AxiosResponse<RawTriggerData>>((resolve, reject) => {
      TriggersApi.setTrigger(trigger)
          .then(resolve)
          .catch(reject)
    });
  }

  static deleteTrigger(id: string) {
    return new Promise<AxiosResponse<void>>((resolve, reject) => {
      TriggersApi.deleteTrigger(id)
          .then(resolve)
          .catch(reject)
    });
  }

  static updateTriggers() {
    return new Promise<void>((resolve, reject) => {
      const triggers = this.getTriggersFromStore();

      if (!triggers) {
        Store.dispatch("updateTriggers")
            .then(() => {
              resolve();
            })
            .catch(err => {
              reject(err);
            });
      } else {
        resolve();
      }
    });
  }

  static getTriggersFromStore() {
    return Store.state.triggersState.triggers;
  }

  static updateFrontendTriggers() {
    if (Store.state.chatState.chat) {
      const triggers = this.getTriggersFromStore();

      if (triggers) {
        const chatTriggers = triggers.filter(trigger => trigger.chat_id === Store.state.chatState.chat!.chat_id)

        Store.commit("updateFrontendTriggers", chatTriggers);
      }
    } else {
      Store.commit("updateFrontendTriggers", null);
    }
  }

  static deleteFrontendTrigger(id: string) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$confirm({
        title: i18n.t("trigger_confirm_remove").toString(),
        content: i18n.t("trigger_confirm_remove_text").toString(),
        okText: i18n.t("accept").toString(),
        cancelText: i18n.t("reject").toString(),
        onOk: () => {
          this.deleteTrigger(id)
              .then(res => {
                Store.commit("deleteFrontendTrigger", id);

                resolve(res);
              })
              .catch(err => {
                reject(err);
              });
        },
      })
    });
  }

  static setTriggerFrontEnd(data: RawTriggerData, isCopy = false) {
    let triggerTemplate = data;

    triggerTemplate = Object.assign(this.getTriggerTemplate(), triggerTemplate);

    Store.commit("setNewTrigger", triggerTemplate);
  }

  static getTriggerTemplate(): RawTriggerData {
    return getTriggerTemplate()
  }

  static getTriggerFormVuex(id: string) {
    const triggers = Store.state.triggersState.frontendTriggers ?? [];
    const neededIndex = triggers.findIndex(t => t.id === id);

    return triggers[neededIndex];
  }

  static copyTrigger(id: string) {
    const neededItem = cloneDeep(VuTriggers.getTriggerFormVuex(id));

    delete neededItem.id;
    delete neededItem.owner_id;

    this.setTriggerFrontEnd(neededItem, true);
  }
}
